<template>
    <section class="o-flexible-module"
        :class="{ 'o-flexible-module--flush': settings.spacing == 'flush' }">
        <div class="grid-container" v-if="settings.showComponentTitle">
            <div class="grid-x grid-margin-x">
                <div class="small-12 cell">
                    <div class="utl-heading-beta">
                        Logos
                    </div>
                </div>
            </div>
        </div>

        <div :class="`c-logos c-logos--${settings.theme}`">
            <div class="grid-container">
                <div class="grid-x grid-margin-x align-middle">
                    <div class="small-12 medium-1 cell">
                        <h2 class="c-logos__title" data-aos="fade-up">
                            {{ title }}
                        </h2>
                    </div>

                    <div class="small-12 medium-4 cell">
                        <div class="grid-x grid-margin-x">
                            <div class="cell small-6 medium-6" v-for="(logo, i) in firstTwoLogos" :key="i">
                                <NuxtLink
                                        v-if="logo.link"
                                        class="c-logos__media"
                                        :to="$convertUrl(logo.link.url)"
                                        :target="logo.link.target">
                                        <img :src="logo.logo.sourceUrl" :srcset="logo.logo.srcSet" :sizes="logo.logo.sizes" :alt="logo.logo.altText" :title="logo.logo.title" />
                                    </NuxtLink>

                                    <div class="c-logos__media" v-else>
                                        <img :src="logo.logo.sourceUrl" :srcset="logo.logo.srcSet" :sizes="logo.logo.sizes" :alt="logo.logo.altText" :title="logo.logo.title" />
                                    </div>
                            </div>
                        </div>
                    </div>

                   

                    <div class="small-12 medium-6 cell">
                        <div class="s-swiper">
                            <swiper-container
                                :autoplay="{
                                    delay: 2000
                                }"
                                :breakpoints="{
                                    1024: {
                                        slidesPerView: 4
                                    }
                                }"
                                :loop="true"
                                :slides-per-view="2"
                                :space-between="40">
                                <swiper-slide v-for="(logo, index) in getRemainingLogos" :key="index">
                                    <NuxtLink
                                        v-if="logo.link"
                                        class="c-logos__media"
                                        :to="$convertUrl(logo.link.url)"
                                        :target="logo.link.target">
                                        <img :src="logo.logo.sourceUrl" :srcset="logo.logo.srcSet" :sizes="logo.logo.sizes" :alt="logo.logo.altText" :title="logo.logo.title" />
                                    </NuxtLink>

                                    <div class="c-logos__media" v-else>
                                        <img :src="logo.logo.sourceUrl" :srcset="logo.logo.srcSet" :sizes="logo.logo.sizes" :alt="logo.logo.altText" :title="logo.logo.title" />
                                    </div>
                                </swiper-slide>
                            </swiper-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>
    const { $instantiateSwiper } = useNuxtApp();


    const props = defineProps({
        title: {
            type: String,
            required: true
        },
        logos: {
            type: Object,
            required: true
        },
        settings: {
            theme: {
                type: String,
                required: false,
            },
            showComponentTitle: {
                type: Boolean,
                required: true,
            },
            spacing: {
                type: String,
                required: true
            }
        }
    });

    const getRemainingLogos =  computed(() => {
    // Ensure that logos is an array and has more than two elements
    if (Array.isArray(props.logos) && props.logos.length > 2) {
      return props.logos.slice(2); // Extract logos starting from the third element
    } else {
      return []; // Return an empty array if there are fewer than three logos
    }
  });

    const firstTwoLogos = computed(() => {
    // Ensure that logos is an array and has at least two elements
        if (Array.isArray(props.logos) && props.logos.length >= 2) {
        return props.logos.slice(0, 2); // Extract the first two logos from the array
        } else {
        return props.logos; // Return the entire logos array if there are fewer than two elements
        }
    });
</script>
